<template>
  <div class="newsfeed-item mb-4">
    <p class="title">{{ title }}</p>
    <p class="news-body">{{ body }}</p>
    <a class="news-link" href="#"
      >{{ createdAt }} | <span class="learn-more">Learn More</span></a
    >
  </div>
</template>

<script>
export default {
  name: 'newsfeed-item',
  props: ['title', 'body', 'createdAt'],
}
</script>

<style scoped>
.newsfeed-item .title {
  color: var(--pq-font-color-shade3);
  margin-bottom: 0.5rem;
}

.newsfeed-item .news-body {
  font-size: 0.8rem;
  color: var(--pq-font-color-shade1);
  margin-bottom: 0.5rem;
}

.newsfeed-item .news-link {
  font-size: 0.8rem;
  color: var(--pq-font-color-shade3);
  text-decoration: none;
}

.newsfeed-item .news-link .learn-more {
  color: #6d81b9;
}
</style>
