<template>
  <div class="dashboard-wrapper container-fluid" style="overflow: hidden auto">
    <div class="row h-100">
      <div
        class="col-xl-2 d-none d-xl-flex flex-column dashboard-side-navbar-col p-lr-30"
      >
        <DashboardSideNavbar />
      </div>
      <div class="col-lg-12 col-xl-10 p-lr-30">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
import DashboardSideNavbar from '@/components/navbars/dashboard-side-navbar'

export default {
  name: 'DashboardLayout',
  components: {
    DashboardSideNavbar,
  },
}
</script>

<style>
.dashboard-page > section header h2,
.main > section header h1 {
  color: var(--pq-font-color-shade1);
}
.dashboard-wrapper {
  color: var(--pq-font-color-shade2);
  background-color: var(--pq-blue-shade1);
  flex: 1;
}

.dashboard-side-navbar-col {
  border-right: 1px solid var(--pq-color-border-dark);
  border-bottom: none;
  border-bottom: 1px solid var(--pq-color-border-dark);
}

@media (max-width: 1199.98px) {
  .dashboard-side-navbar-col {
    position: fixed;
    max-width: none;
    z-index: 999;
    background-color: var(--pq-blue-shade1);
  }
}
</style>
