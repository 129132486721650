import { render, staticRenderFns } from "./newsfeed-panel-dashboard.vue?vue&type=template&id=864c4dce&scoped=true&"
import script from "./newsfeed-panel-dashboard.vue?vue&type=script&lang=js&"
export * from "./newsfeed-panel-dashboard.vue?vue&type=script&lang=js&"
import style0 from "./newsfeed-panel-dashboard.vue?vue&type=style&index=0&id=864c4dce&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "864c4dce",
  null
  
)

export default component.exports