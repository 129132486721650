<template>
  <div class="pq-newsfeed">
    <div v-for="(item, index) in news" :key="index">
      <NewsfeedItem
        :title="item.title"
        :body="item.body"
        :createdAt="$utilFunc.convertTime(item.createdAt)"
      />
    </div>
  </div>
</template>

<script>
import { FETCH_NEWS } from '@/store/actions.type'
import NewsfeedItem from '@/components/newsfeed/newsfeed-item'

export default {
  name: 'newsfeed-panel-dashboard',
  components: {
    NewsfeedItem,
  },
  data() {
    return {
      news: null,
    }
  },
  async mounted() {
    this.news = await this.$store.dispatch(FETCH_NEWS)
  },
}
</script>

<style scoped>
.pq-newsfeed {
  padding-top: 5rem;
}
</style>
