<template>
  <div class="wrapper-nav position-fixed h-100">
    <div class="pt-5 pb-5">
      <b-nav vertical>
        <li class="nav-item">
          <router-link to="/" class="nav-link">Home</router-link>
        </li>
        <li class="nav-item">
          <router-link
            to="/projects"
            class="nav-link"
            v-bind:class="[isAuthenticated ? '' : 'nav-link-disabled']"
            :event="isAuthenticated ? 'click' : ''"
          >
            My Projects
          </router-link>
        </li>
        <li class="nav-item">
          <router-link to="/published" class="nav-link">Published</router-link>
        </li>
        <li class="nav-item">
          <a href="https://docs.piquasso.com/" target="_blank" class="nav-link"
            >Documentation</a
          >
        </li>
      </b-nav>
    </div>
    <NewsfeedPanelDashboard class="d-none d-xl-block" />
  </div>
</template>

<script>
import NewsfeedPanelDashboard from '@/components/newsfeed/newsfeed-panel-dashboard'
import { mapGetters } from 'vuex'

export default {
  name: 'DashboardSideNavbar',
  components: {
    NewsfeedPanelDashboard,
  },
  computed: {
    ...mapGetters(['isAuthenticated']),
  },
}
</script>

<style scoped>
.nav-item {
  padding: 0.5rem 0;
}

.nav-link {
  color: var(--pq-font-color-shade4);
  font-size: 1rem;
  padding: 0.5rem 0;
}

.nav-item .router-link-exact-active {
  color: var(--pq-font-color-shade1);
  font-weight: 500;
}

.nav-link-disabled {
  color: #4b5665;
}

@media (max-width: 1199.98px) {
  .wrapper-nav {
    position: relative;
  }
}
</style>
